<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h1 class="text-right">
          <v-btn @click="printPage" icon color="primary">
            <v-icon>mdi-cloud-print</v-icon>
          </v-btn>
        </h1>
        <div style="column-count:2;" class="printable">
          <mcq-for-print class="dont-break" v-for="(mcq, index) in mcqs" :index="index" :mcq="mcq" :key="mcq.id"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
import McqForPrint from "@/components/admin/mcq/McqForPrint";

export default {
  components: {
    McqForPrint,
  },
  data: () => ({
    showEditButton: true,
    loading: false,
    mcqs: [],
    selectedMcq: null,
    editMode: false,
    dialog: false,
    allowExport: process.env.VUE_APP_EXPORT_MCQ_STORE === 'true',
    question: '',
    a: '',
    b: '',
    c: '',
    d: '',
    answer: '',
    answer_description: '',
    question_photo: null,
    answer_photo: null,
    snackbar: false,
    text: '',
    color: 'blue',

  }),
  computed: {
    mcqSubStoreId: function () {
      return this.$route.params.mcqSubStoreId;
    },
    form: function () {
      let formData = new FormData();
      formData.append('mcq_sub_store_id', this.$route.params.mcqSubStoreId);
      if (this.editMode) {
        formData.append('_method', 'PUT');
      }
      if (this.question) {
        formData.append('question', this.question);
      }
      if (this.a) {
        formData.append('a', this.a);
      }
      if (this.b) {
        formData.append('b', this.b);
      }
      if (this.c) {
        formData.append('c', this.c);
      }
      if (this.d) {
        formData.append('d', this.d);
      }
      if (this.answer) {
        formData.append('answer', this.answer);
      }
      if (this.answer_description) {
        formData.append('answer_description', this.answer_description);
      }
      if (this.question_photo) {
        formData.append('question_photo', this.question_photo);
      }
      if (this.answer_photo) {
        formData.append('answer_photo', this.answer_photo);
      }
      return formData;
    },
    answerOptions: function () {
      let options = [this.a, this.b, this.c, this.d];
      return options.filter(function (el) {
        return el !== '';
      });
    }
  },
  methods: {
    printPage(){
      print()
    },
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    createMcq() {
      this.editMode = false;
      this.selectedMcq = null;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
    },
    edit(mcq) {
      this.selectedMcq = mcq;
      this.dialog = true;
      this.editMode = true;
      this.question = mcq.question;
      this.a = mcq.a;
      this.b = mcq.b;
      this.c = mcq.c;
      this.d = mcq.d;
      this.answer = mcq.answer;
      this.answer_description = mcq.answer_description;
      this.question_photo = null;
      this.answer_photo = null;
    },
    submit() {
      const url = 'admin/mcqs';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New mcq created';
        this.loadMcqs();
        this.dialog = false;
      })
    },
    update() {
      const url = 'admin/mcqs/' + this.selectedMcq.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Mcq updated';
        this.loadMcqs();
        this.dialog = false;
      })
    },
    loadMcqs() {
      const url = 'admin/mcqs?mcq-sub-store=' + this.$route.params.mcqSubStoreId;
      axios.get(url).then((response) => {
        this.mcqs = response.data;
      })
    },
    clear() {
      this.editMode = false;
      this.question = '';
      this.a = '';
      this.b = '';
      this.c = '';
      this.d = '';
      this.answer = '';
      this.answer_description = '';
      this.question_photo = null;
      this.answer_photo = null;
      this.selectedMcq = null;
      this.$refs.observer.reset();
    },
  },
  mounted() {
    this.loadMcqs();
  }
}
</script>
<style lang="scss">

.dont-break {
  break-inside: avoid !important;
  page-break-inside: avoid !important;
  display: grid;
}

@page {
  size: A4;
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 0 1cm;
  }

  body * {
    visibility: hidden;
  }
  .printable, .printable * {
    visibility: visible;
  }
  .printable {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>