<template xmlns:v-katex="http://www.w3.org/1999/xhtml">
  <v-card flat class="my-2 bangla dont-break">
    <v-card-text class="py-0">
      <v-radio-group
          class="dont-break"
          v-model="answer"
          column
      >
        <div v-html="en2bn(index+1)+'. '+mcq.question" v-katex:auto class="mb-1 ml-1"/>

        <v-img
            v-if="mcq.question_photo"
            :aspect-ratio="16/9"
            :width="320"
            :src="mcq.question_photo"
            class="mb-2"
        ></v-img>

        <v-radio
            color="green"
            :value="mcq.a"
            ripple
            v-katex:auto
        >
          <template v-slot:label>
            <div v-katex:auto v-html="mcq.a" class="black--text"></div>
          </template>
        </v-radio>
        <v-radio
            color="green"
            :value="mcq.b"
            ripple
            v-katex:auto
        >
          <template v-slot:label>
            <div v-katex:auto v-html="mcq.b" class="black--text"></div>
          </template>
        </v-radio>
        <v-radio
            color="green"
            :value="mcq.c"
            ripple
            v-katex:auto
        >
          <template v-slot:label>
            <div v-katex:auto v-html="mcq.c" class="black--text"></div>
          </template>
        </v-radio>
        <v-radio
            color="green"
            :value="mcq.d"
            ripple
            v-katex:auto
        >
          <template v-slot:label>
            <div v-katex:auto v-html="mcq.d" class="black--text"></div>
          </template>
        </v-radio>
      </v-radio-group>
      <div v-if="mcq.answer_description" v-html="mcq.answer_description" v-katex:auto></div>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from 'axios'

export default {
  props: ['mcq', 'index', 'revision', 'type'],
  data() {
    return {
      viewComment: false,
      isDiscarded: false,
      discardText: 'Discard',
      answer: this.mcq.answer,
      width: 200,
      reviseText: 'Confirm',
      comments: [],
      courseName: '',
      verified: this.mcq.verified,
    }
  },
  methods: {
    en2bn(input) {
      input = input.toString()
      let numbers = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
      let output = []
      for (let i = 0; i < input.length; ++i) {
        output.push(numbers[input[i]])
      }
      return output.join('');
    },
    loadComments() {
      const url = 'admin/mcq-comments?id=' + this.mcq.id
      axios.get(url).then(response => {
        this.comments = response.data
      })
    },
    getCourseName() {
      const url = 'admin/mcqs/' + this.mcq.id + '?filter=course'
      axios.get(url).then(response => {
        this.courseName = response.data
      })
    }
  },
  mounted() {
    if (this.type === 'search') {
      this.getCourseName()
    }
  },
  watch: {
    viewComment() {
      if (this.viewComment) {
        this.loadComments()
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import url('https://fonts.maateen.me/adorsho-lipi/font.css');

.bangla {
  font-family: 'AdorshoLipi', sans-serif;
}
.dont-break {
  break-inside: avoid !important;
  page-break-inside: avoid !important;
}
</style>